@import url(https://cdnjs.cloudflare.com/ajax/libs/typebase.css/0.5.0/typebase.min.css);
@import url(https://vkbansal.github.io/react-contextmenu/styles.034090.css);

body {
  margin: 0 5%;
}
@media only screen and (min-width: 800px) {
  .colA {
    width: 45%;
    float: left;
    margin-right: 5%;
  }
  .colB {
    width: 45%;
    float: left;
  }
}

